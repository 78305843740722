import { useEffect } from "react";
import axios from "axios";
import qs from 'qs';

const AOLOAuthCallback = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');

  useEffect(() => {
    const payload = {
      code,
      grant_type: 'authorization_code',
      redirect_uri: 'https://web-auth.rickyjulpiter.com/aol-oauth-callback',
    }

    const authorization = btoa('7214daf8-d89f-43bb-986f-09e84f370274:a809ba655d4b641128ca08b88856b80f');

    console.log('payload', payload);
    console.log('authorization', authorization);

    axios.post('https://account.accurate.id/oauth/token', qs.stringify(payload), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Basic ${authorization}`
      }
    })
      .then(response => {
        console.log('Post created:', response.data);
        // Do something with the response
      })
      .catch(error => {
        console.error('Error creating post:', error);
        // Handle error
      });
  }, []);

  return (
    <div>
      Processing
    </div>
  );
}

export default AOLOAuthCallback;
