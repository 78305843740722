const App = () => {
  return (
    <div>
      <form action="https://account.accurate.id/oauth/authorize" method="POST">
        <input name="client_id" value="7214daf8-d89f-43bb-986f-09e84f370274"/>
        <input name="response_type" value="code"/>
        <input name="redirect_uri" value="https://web-auth.rickyjulpiter.com/aol-oauth-callback"/>
        <input name="scope" value="item_view item_save sales_invoice_view"/>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default App;
